import { Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { FormattedMessage } from "react-intl";
import { StyledA } from "../styled-links";

const StyledSection = styled("section")`
  background: #53e5df;
  padding-top: 72px;
  color: #002f3d;
`;

const StyledArtistFill = styled(Grid)`
  background: #ff8585;
  border-radius: 30px 30px 0px 0px;
  height: 100%;
  margin-right: 72px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-right: auto;
  }
`;

const StyledCharityFill = styled(Grid)`
  background: #e64c61;
  border-radius: 0px 0px 30px 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 72px;
  position: relative;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-right: auto;
  }
`;

const StyledHorizontalLine = styled("div")`
  height: 6px;
  background-color: #ffffff;
  border-radius: 3px;
  width: 50%;
  position: absolute;
  left: 90%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 21%;
  }
`;

const StyledVerticalLine = styled("div")`
  height: 6px;
  background-color: #ffffff;
  border-radius: 3px;
  width: 24px;
  position: absolute;
  left: 130%;
  transform: rotate(90deg);

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 32px;
    left: 95%;
  }
`;

const StyledTitleTypography = styled(Typography)`
  margin-bottom: 48px;
  text-align: center;
  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 40px;
  }
`;

const StyledArtistGrid = styled(Grid)`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 248px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    height: 300px;
  }
`;

const StyledCharityGrid = styled(Grid)`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 400px;
  margin-right: 44px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    height: 550px;
  }
`;

const RevenueArrow = () => (
  <>
    <StyledHorizontalLine />
    <StyledVerticalLine />
  </>
);

export const RevenueShare = () => (
  <StyledSection>
    <Container maxWidth="md">
      <StyledTitleTypography variant="h2">
        <FormattedMessage defaultMessage="Mint Revenue Share" />
      </StyledTitleTypography>

      <StyledArtistGrid item container xs={12}>
        <StyledArtistFill item xs={3} sm={2}>
          <Typography
            fontWeight="900"
            textAlign="center"
            fontSize="32px"
            color="#FFFFFF"
          >
            25%
          </Typography>
          <RevenueArrow />
        </StyledArtistFill>
        <Grid container item xs={8}>
          <Grid item xs={12} sx={{ textTransform: "uppercase" }}>
            <Typography fontWeight="900" fontSize="24px">
              Artist
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "24px" }}>
            <Typography>
              This portion goes to supporting Cori Huang (
              <StyledA
                href="https://twitter.com/corcorarium"
                target="_blank"
                rel="noreferrer"
                $linkColor="#002F3D"
                $visitedColor="#006699"
              >
                @corcorarium
              </StyledA>
              ), the artist and designer behind the Orcanauts. To learn more
              about her, check out{" "}
              <StyledA
                href="https://orca-so.medium.com/orcanauts-meet-the-artist-7b4895ec76be"
                target="_blank"
                rel="noreferrer"
                $linkColor="#002F3D"
                $visitedColor="#006699"
              >
                this interview
              </StyledA>{" "}
              and{" "}
              <StyledA
                href="https://youtu.be/Cm2dWXLQ29I"
                target="_blank"
                rel="noreferrer"
                $linkColor="#002F3D"
                $visitedColor="#006699"
              >
                OrcaPod episode #7!
              </StyledA>
            </Typography>
          </Grid>
        </Grid>
      </StyledArtistGrid>
      <StyledCharityGrid item container xs={12}>
        <StyledCharityFill item xs={3} sm={2}>
          <Typography
            fontWeight="900"
            textAlign="center"
            fontSize="32px"
            color="#FFFFFF"
          >
            75%
          </Typography>
          <RevenueArrow />
        </StyledCharityFill>
        <Grid container item xs={8}>
          <Grid item xs={12} sx={{ textTransform: "uppercase" }}>
            <Typography fontWeight="900" fontSize="24px">
              Charity
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "24px" }}>
            Orca is partnering with{" "}
            <StyledA
              href="https://www.aflatoun.org/"
              target="_blank"
              rel="noreferrer"
              $linkColor="#002F3D"
              $visitedColor="#006699"
            >
              Aflatoun
            </StyledA>{" "}
            to develop a Crypto and DeFi education curriculum for children. The
            initial pilot program will target at least 4 regions and reach over
            25,000 youth!
          </Grid>
        </Grid>
      </StyledCharityGrid>
    </Container>
  </StyledSection>
);
