import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { FormattedMessage } from "react-intl";
import { Orcanaut } from "../nifty-component";

import topTransitionSvg from "./assets/ripples-aqua-2.svg";
import corcorariumPng from "./assets/corcorarium.png";
import oritheorcaJpg from "./assets/ori.jpg";
import jnwngPng from "./assets/jnwng.png";
import pbmilanPng from "./assets/pbmilan.png";
import scubaPng from "./assets/scuba.png";
import kayzizzleJpg from "./assets/kayzizzle.jpg";

const StyledTopDivider = styled("div")`
  height: 116px;
  background-image: url(${topTransitionSvg});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto;
`;

const StyledCard = styled(Card)`
  border-radius: 16px;
  width: 240px;
  height: 318px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    position: relative;
    width: 160px;
    height: 260px;
  }

  overflow: initial;
`;

const StyledCardContent = styled(CardContent)`
  border-radius: 20px;
  padding-top: 0;
  display: flex;
  align-items: center;

  ${(props) => props.theme.breakpoints.down("sm")} {
    position: relative;
  }
`;

const StyledCardMedia = styled(CardMedia)`
  height: 200px;
  width: 200px;
  border-radius: 16px;
  margin: 20px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: 120px;
    width: 120px;
  }
`;

const StyledTeamMember = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

const StyledOrcanautContainer = styled("div")`
  border-radius: 50%;
  border: 2px solid #1265e2;
  height: 68px;
  width: 68px;
  display: inline-flex;

  ${(props) => props.theme.breakpoints.down("sm")} {
    position: absolute;
    left: 45px;
    top: -30px;
  }
`;

const StyledTitleBox = styled(Box)`
  display: inline-flex;
  flex-direction: column;
  margin-left: 8px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: 44px;
    margin-left: 0;
    text-align: center;
    width: 100%;
  }
`;

type TeamMemberProps = {
  twitterHandle: string;
  title: string;
  image: string;
  orcanaut: JSX.Element;
};

const TeamMember = (props: TeamMemberProps) => (
  <StyledTeamMember>
    <StyledCard>
      <StyledCardMedia image={props.image} />
      <StyledCardContent>
        <StyledOrcanautContainer>{props.orcanaut}</StyledOrcanautContainer>
        <StyledTitleBox>
          <Typography fontSize="16px">
            <a
              href={`https://twitter.com/${props.twitterHandle.replace(
                "@",
                ""
              )}`}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                fontWeight: "bold",
                color: "#003366",
              }}
            >
              {props.twitterHandle}
            </a>
          </Typography>
          <Typography
            variant="body2"
            color="#003366"
            fontSize="16px"
            whiteSpace="nowrap"
          >
            {props.title}
          </Typography>
        </StyledTitleBox>
      </StyledCardContent>
    </StyledCard>
  </StyledTeamMember>
);

const StyledSection = styled("section")`
  background-color: #1265e2;
  padding-bottom: 48px;
`;

export const Team = () => (
  <StyledSection>
    <StyledTopDivider />
    <Container>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography
          variant="h2"
          textAlign="center"
          color="#FFFFFF"
          sx={{ marginTop: "24px", marginBottom: "24px" }}
        >
          <FormattedMessage defaultMessage="Team" />
        </Typography>
        <Grid
          container
          item
          xs={12}
          sm={10}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={6} md={4}>
            <TeamMember
              twitterHandle="@oritheorca"
              title="ship captain"
              image={oritheorcaJpg}
              orcanaut={
                <Orcanaut
                  background="beach"
                  body="orca"
                  hat="bandana"
                  eyes="sol-sunglasses"
                  mouth="smirk"
                  accessory="mug"
                  imgProps={{
                    width: "64px",
                    borderRadius: "50%",
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TeamMember
              twitterHandle="@corcorarium"
              title="color collector"
              image={corcorariumPng}
              orcanaut={
                <Orcanaut
                  background="tokyo"
                  body="holo"
                  hat="headphones"
                  eyes="mischievous"
                  mouth="vampire"
                  accessory="none"
                  imgProps={{
                    width: "64px",
                    borderRadius: "50%",
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TeamMember
              twitterHandle="@jnwng"
              title="purveyor of wares"
              image={jnwngPng}
              orcanaut={
                <Orcanaut
                  background="nyc-night"
                  body="holo"
                  hat="baseball"
                  eyes="aviators"
                  mouth="tongue"
                  accessory="samo"
                  imgProps={{
                    width: "64px",
                    borderRadius: "50%",
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TeamMember
              twitterHandle="@pbmilan"
              title="navigator"
              image={pbmilanPng}
              orcanaut={
                <Orcanaut
                  background="outer-space"
                  body="orca"
                  hat="astronaut"
                  eyes="happy"
                  mouth="vampire"
                  accessory="phantom"
                  imgProps={{
                    width: "64px",
                    borderRadius: "50%",
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TeamMember
              twitterHandle="@0xscuba"
              title="diver of depths"
              image={scubaPng}
              orcanaut={
                <Orcanaut
                  background="beach"
                  body="orca"
                  hat="none"
                  eyes="snorkel"
                  mouth="vampire"
                  accessory="none"
                  imgProps={{
                    width: "64px",
                    borderRadius: "50%",
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TeamMember
              twitterHandle="@KayZizzle"
              title="murky wavefinder"
              image={kayzizzleJpg}
              orcanaut={
                <Orcanaut
                  background="outer-space"
                  body="orca"
                  hat="astronaut"
                  eyes="anime"
                  mouth="smirk"
                  accessory="kelp"
                  imgProps={{
                    width: "64px",
                    borderRadius: "50%",
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  </StyledSection>
);
