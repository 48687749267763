import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  List,
  styled,
  Typography,
} from "@mui/material";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletName } from "@solana/wallet-adapter-wallets";
import React, { FC, ReactElement, SyntheticEvent, useCallback } from "react";
import CloseIconSvg from "./assets/icon-x.svg";
import { useWalletDialog } from "./useWalletDialog";
import { WalletListItem } from "./WalletListItem";
import bubblesSvg from "./assets/bubbles.svg";

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 16px;

    background-image: url(${bubblesSvg}),
      linear-gradient(180deg, #1265e2 0%, #0e51dc 100%);
    background-position: center bottom;
    background-repeat: no-repeat;
  }
`;

export interface WalletDialogProps extends Omit<DialogProps, "title" | "open"> {
  featuredWallets?: number;
  title?: ReactElement;
}

export const WalletDialog: FC<WalletDialogProps> = ({
  title = "Connect Wallet",
  featuredWallets = 3,
  onClose,
  ...props
}) => {
  const { wallets, select } = useWallet();
  const { open, setOpen } = useWalletDialog();

  const handleClose = useCallback(
    (event: SyntheticEvent, reason?: "backdropClick" | "escapeKeyDown") => {
      if (onClose) onClose(event, reason!);
      if (!event.defaultPrevented) setOpen(false);
    },
    [setOpen, onClose]
  );

  const handleWalletClick = useCallback(
    (event: SyntheticEvent, walletName: WalletName) => {
      select(walletName);
      handleClose(event);
    },
    [select, handleClose]
  );

  return (
    <StyledDialog open={open} onClose={handleClose} {...props}>
      <IconButton
        onClick={handleClose}
        sx={{
          width: "24px",
          height: "24px",
          alignSelf: "flex-end",
          marginTop: "12px",
          marginRight: "12px",
        }}
      >
        <img src={CloseIconSvg} alt="Close button" />
      </IconButton>
      <DialogTitle>
        <Typography
          variant="h2"
          color="#FFFFFF"
          fontWeight="400"
          fontSize="32px"
          textAlign="center"
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          {wallets.map((wallet) => (
            <WalletListItem
              key={wallet.name}
              onClick={(event) => handleWalletClick(event, wallet.name)}
              wallet={wallet}
            />
          ))}
        </List>
      </DialogContent>
    </StyledDialog>
  );
};
