import { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {Box, Grid, Button, ButtonGroup, Typography, styled, css } from "@mui/material";

import defaults from "./pixel-defaults.json";
import SwapForm from "./SwapForm";

const composeAttributes = traits => {
  let attributes = defaults;
  attributes.hat.src = `pixel-assets/hat-${traits.hat}.png`;
  attributes.body.src = `pixel-assets/body-${traits.body}.png`;
  attributes.eyes.src = `pixel-assets/eyes-${traits.eyes}.png`;
  attributes.mouth.src = `pixel-assets/mouth-${traits.mouth}.png`;
  attributes.accessory.src = `pixel-assets/accessory-${traits.accessory}.png`;
  attributes.background.src = `pixel-assets/background-${traits.background}.png`;
  return attributes;
};


const baseButtonStyles = css`
  height: 74px;
  border-radius: 50px;
  padding: 20px 40px;
  text-transform: none;
  margin-bottom: 10px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

const StyledButton = styled(Button)`
  ${baseButtonStyles}
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(0, 0, 0, 0.25) 100%
    ),
    navy;
  background-blend-mode: overlay, normal;

  &:hover {
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(0, 0, 0, 0.25) 100%
      ),
      #5245ca;
`;

const StyledTypography = styled(Typography)`
  color: #ffffff;
  font-weight: 800;
  font-size: 28px;
`;

const PixelImg = props => {
  const [hat, setHat] = useState(props.traits.hat);
  const [body, setBody] = useState(props.traits.body);
  const [eyes, setEyes] = useState(props.traits.eyes);
  const [mouth, setMouth] = useState(props.traits.mouth);
  const [accessory, setAccessory] = useState(props.traits.accessory);
  const [background, setBackground] = useState(props.traits.background);
  const canvasRef = useRef();

  const history = useHistory();
  const attrs = composeAttributes({
    hat,
    body,
    eyes,
    mouth,
    accessory,
    background
  });

  const draw = (ctx, attrs, frameCount) => {

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const scale = ctx.canvas.width / 40;

    const hatSprite = new Image();
    const bodySprite = new Image();
    const eyesSprite = new Image();
    const mouthSprite = new Image();
    const accessorySprite = new Image();
    const backgroundSprite = new Image();

    backgroundSprite.src = attrs.background.src;
    hatSprite.src = attrs.hat.src;
    bodySprite.src = attrs.body.src;
    eyesSprite.src = attrs.eyes.src;
    mouthSprite.src = attrs.mouth.src;
    accessorySprite.src = attrs.accessory.src;

    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      backgroundSprite,
      attrs.background.width,
      0,
      attrs.background.width,
      attrs.background.height,
      attrs.background.offsetX * scale,
      attrs.background.offsetY * scale,
      attrs.background.width * scale,
      attrs.background.height * scale
    );

    ctx.drawImage(
      bodySprite,
      attrs.body.width,
      0,
      attrs.body.width,
      attrs.body.height,
      attrs.body.offsetX * scale,
      attrs.body.offsetY * scale,
      attrs.body.width * scale,
      attrs.body.height * scale
    );

    ctx.drawImage(
      hatSprite,
      attrs.hat.width,
      0,
      attrs.hat.width,
      attrs.hat.height,
      attrs.hat.offsetX * scale,
      attrs.hat.offsetY * scale,
      attrs.hat.width * scale,
      attrs.hat.height * scale
    );

    ctx.drawImage(
      eyesSprite,
      attrs.eyes.width,
      0,
      attrs.eyes.width,
      attrs.eyes.height,
      attrs.eyes.offsetX * scale,
      attrs.eyes.offsetY * scale,
      attrs.eyes.width * scale,
      attrs.eyes.height * scale
    );

    ctx.drawImage(
      mouthSprite,
      attrs.mouth.width,
      0,
      attrs.mouth.width,
      attrs.mouth.height,
      attrs.mouth.offsetX * scale,
      attrs.mouth.offsetY * scale,
      attrs.mouth.width * scale,
      attrs.mouth.height * scale
    );

    ctx.drawImage(
      accessorySprite,
      attrs.accessory.width,
      0,
      attrs.accessory.width,
      attrs.accessory.height,
      attrs.accessory.offsetX * scale,
      attrs.accessory.offsetY * scale,
      attrs.accessory.width * scale,
      attrs.accessory.height * scale
    );
  };

  const downloadPixelnaut = () => {
    const canvas = canvasRef.current;
    const url = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "demo-pixelnaut-pfp.png";
    link.href = url;
    link.click();
  };

  const sharePixelnaut = () => {
    history.push(`/share/${body}/${hat}/${mouth}/${eyes}/${accessory}/${background}`);
  };

  useEffect(() => {
    let animationFrameCount = 0;
    let animationFrameId;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const render = () => {
      setTimeout(() => {
        animationFrameCount++;
        draw(context, attrs, animationFrameCount % 10); // keeps frameCount between 0 and 10.
        animationFrameId = window.requestAnimationFrame(render);
      }, 400); // use this to change the pace of the gif
    };
    render();
    return () => window.cancelAnimationFrame(animationFrameId);
  }, [draw]);
  // <canvas> auto-resizes to fit the parent div
  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justifyContent="center"
      align="center"
    >
      <Grid item sx={12} mb={2}>
        <Typography
          variant="h2"
          color="black"
          textAlign="center"
          fontSize="40px"
          my={1}
        >
          Pixelnauts #{props.traits.num}
        </Typography>
        <canvas ref={canvasRef} width="200" height="200"></canvas>
        <SwapForm
          setHat={setHat}
          setEyes={setEyes}
          setMouth={setMouth}
          setAccessory={setAccessory}
          setBackground={setBackground}
          defaultHat={props.traits.hat}
          defaultEyes={props.traits.eyes}
          defaultMouth={props.traits.mouth}
          defaultAccessory={props.traits.accessory}
          defaultBackground={props.traits.background}
        />
      </Grid>
      <ButtonGroup>
        <StyledButton onClick={downloadPixelnaut}>
          <StyledTypography>Download</StyledTypography>
        </StyledButton>
        <StyledButton onClick={sharePixelnaut}>
          <StyledTypography>Animate</StyledTypography>
        </StyledButton>
      </ButtonGroup>
    </Grid>
  );
};
export default PixelImg;
