import { Box, Grid, Typography, Container } from "@mui/material";
import { styled } from "@mui/system";
import { FormattedMessage } from "react-intl";
import { OutlinedButton } from "../buttons/Button";
import topTransitionSvg from "./assets/ripples-cobalt.svg";
import twitterSvg from "./assets/twitter.svg";
import discordSvg from "./assets/discord.svg";
import { StyledA } from "../styled-links";

const StyledSection = styled("section")`
  background-color: #e75569;
  min-height: 540px;
  ${(props) => props.theme.breakpoints.down("md")} {
    min-height: 640px;
  }
`;

const StyledTopDivider = styled("div")`
  background-image: url(${topTransitionSvg});
  background-repeat: no-repeat;
  background-position: center;
  height: 116px;
  margin-bottom: 48px;
`;

const StyledOutlinedButton = styled(OutlinedButton)`
  margin: 0 auto;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-right: 24px;
  }
`;

const StyledTitleTypography = styled(Typography)`
  text-align: center;
  color: #ffffff;
  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 32px;
  }
`;

export const Footer = () => (
  <StyledSection>
    <StyledTopDivider />
    <Container>
      <Grid
        container
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <StyledTitleTypography variant="h2">
          <FormattedMessage defaultMessage="New to Orca?" />
        </StyledTitleTypography>
        <Grid item xs={11}>
          <Typography
            textAlign="center"
            color="#FFFFFF"
            fontWeight="600"
            fontSize="24px"
            sx={{ marginBottom: "32px" }}
          >
            <FormattedMessage defaultMessage="Orca is the most powerful, user-friendly AMM on Solana" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <a
          href="https://orca.so"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <StyledOutlinedButton>
            <Typography color="#FFFFFF">Visit orca.so</Typography>
          </StyledOutlinedButton>
        </a>
        <Box
          flexBasis="100%"
          height="12px"
          display={{ xs: "block", md: "none" }}
        />
        <a
          href="https://discord.orca.so"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <StyledOutlinedButton
            startIcon={<img src={discordSvg} alt="Discord logo" />}
          >
            <Typography color="#FFFFFF">discord.orca.so</Typography>
          </StyledOutlinedButton>
        </a>
        <Box
          flexBasis="100%"
          height="12px"
          display={{ xs: "block", md: "none" }}
        />
        <a
          href="https://twitter.com/orca_so"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <StyledOutlinedButton
            startIcon={<img src={twitterSvg} alt="Twitter logo" />}
          >
            <Typography color="#FFFFFF">twitter.com/orca_so</Typography>
          </StyledOutlinedButton>
        </a>
      </Grid>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop="48px"
        height="100px"
        item
      >
        <Grid item xs={10} lg={6}>
          <Typography color="#FFFFFF" fontSize="16px" textAlign="center">
            The Orcanauts logo draws on the font Mat Saleh, designed by{" "}
            <StyledA
              href="https://www.behance.net/khurasan"
              target="_blank"
              rel="noreferrer"
              $linkColor="#FFFFFF"
              $visitedColor="#FFFFFF"
            >
              Khurasan Studio
            </StyledA>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </StyledSection>
);
