// Enums are great, but this would be better if we could auto-generate this from JSON instead
// Will leave the code-generation for another day.

export const accessory = [
  { name: "none", rarity: 0 },
  { name: "kelp", rarity: 1 },
  { name: "coral", rarity: 1 },
  { name: "scallop", rarity: 1 },
  { name: "hedgehog", rarity: 2 },
  { name: "mug", rarity: 2 },
  { name: "phantom", rarity: 3 },
  { name: "sol-beach-ball", rarity: 3 },
  { name: "saber", rarity: 4 },
  { name: "samo", rarity: 4 },
  { name: "spaceship", rarity: 5 },
] as const;
export type Accessories = Pick<typeof accessory[number], "name">["name"];

export const bg = [
  { name: "basic-honey", rarity: 0 },
  { name: "basic-coral", rarity: 0 },
  { name: "basic-lagoon", rarity: 0 },
  { name: "basic-mellow", rarity: 0 },
  { name: "basic-orchid", rarity: 0 },
  { name: "waves", rarity: 1 },
  { name: "beach", rarity: 1 },
  { name: "river", rarity: 1 },
  { name: "mondrian", rarity: 2 },
  { name: "outer-space", rarity: 2 },
  { name: "defi", rarity: 3 },
  { name: "nyc-night", rarity: 3 },
  { name: "tokyo", rarity: 4 },
  { name: "sol-season", rarity: 5 },
] as const;
export type Backgrounds = Pick<typeof bg[number], "name">["name"];

export const eyes = [
  { name: "basic", rarity: 0 },
  { name: "droopy", rarity: 1 },
  { name: "happy", rarity: 1 },
  { name: "sleepy", rarity: 1 },
  { name: "anime", rarity: 2 },
  { name: "mischievous", rarity: 2 },
  { name: "aviators", rarity: 3 },
  { name: "cartoon", rarity: 3 },
  { name: "snorkel", rarity: 4 },
  { name: "sol-sunglasses", rarity: 5 },
] as const;
export type Eyes = Pick<typeof eyes[number], "name">["name"];

export const hats = [
  { name: "none", rarity: 0 },
  { name: "baseball", rarity: 1 },
  { name: "bucket", rarity: 1 },
  { name: "flower", rarity: 1 },
  { name: "bandana", rarity: 2 },
  { name: "pirate", rarity: 2 },
  { name: "chef", rarity: 3 },
  { name: "headphones", rarity: 3 },
  { name: "ninja", rarity: 4 },
  { name: "astronaut", rarity: 5 },
] as const;
export type Hats = Pick<typeof hats[number], "name">["name"];

export const mouth = [
  { name: "basic", rarity: 0 },
  { name: "frown", rarity: 1 },
  { name: "grin", rarity: 1 },
  { name: "smile", rarity: 1 },
  { name: "cat", rarity: 2 },
  { name: "smirk", rarity: 2 },
  { name: "tongue", rarity: 3 },
  { name: "vampire", rarity: 3 },
  { name: "golden-teeth", rarity: 4 },
  { name: "aurory-mask", rarity: 5 },
] as const;
export type Mouths = Pick<typeof mouth[number], "name">["name"];

export const body = [
  { name: "blue", rarity: 0 },
  { name: "red", rarity: 0 },
  { name: "yellow", rarity: 0 },
  { name: "orca", rarity: 0 },
  { name: "holo", rarity: 0 },
] as const;
export type Bodies = Pick<typeof body[number], "name">["name"];

export const bubbleTraits = { accessory, hats, bg };

export type Traits = {
  accessory: Accessories;
  background: Backgrounds;
  eyes: Eyes;
  hat: Hats;
  mouth: Mouths;
  body: Bodies;
};

export const TraitConfiguration = {
  rootDir: "./assets",
  alternativeVariants: {
    mouth: {
      variant: "grin",
      getFilename: (dna: any) => {
        return `grin-${dna.body.variant}.png`;
      },
    },
  },
  invalidCombinations: [
    [
      { trait: "background", variant: "sol-season" },
      { trait: "accessory", variant: "spaceship" },
    ],
    [
      { trait: "eyes", variant: "snorkel" },
      { trait: "hat", variant: "headphones" },
    ],
    [
      { trait: "eyes", variant: "snorkel" },
      { trait: "hat", variant: "astronaut" },
    ],
  ],
  layeringExceptions: [
    {
      exception: {
        trait: "hat",
        variant: "astronaut",
      },
      layers: [
        { name: "background" },
        { name: "body" },
        { name: "mouth" },
        { name: "eyes" },
        { name: "hat" },
        { name: "accessory" },
      ],
    },
    {
      exception: {
        trait: "hat",
        variant: "flower",
      },
      layers: [
        { name: "background" },
        { name: "body" },
        { name: "mouth" },
        { name: "eyes" },
        { name: "hat" },
        { name: "accessory" },
      ],
    },
    {
      exception: {
        trait: "eyes",
        variant: "snorkel",
      },
      layers: [
        { name: "background" },
        { name: "body" },
        { name: "mouth" },
        { name: "hat" },
        { name: "eyes" },
        { name: "accessory" },
      ],
    },
  ],
  traits: [
    {
      name: "background",
      folder: "bg",
      default: "basic-coral",
    },
    {
      name: "body",
      folder: "body",
      default: "blue",
    },
    {
      name: "hat",
      folder: "hats",
      default: "none",
    },
    {
      name: "mouth",
      folder: "mouth",
      default: "basic",
    },
    {
      name: "eyes",
      folder: "eyes",
      default: "basic",
    },
    {
      name: "accessory",
      folder: "accessory",
      default: "none",
    },
  ],
};
