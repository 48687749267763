import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Box, Grid, Typography } from '@mui/material';

const SwapForm = ({
  setHat,
  setEyes,
  setMouth,
  setAccessory,
  setBackground,
  defaultHat,
  defaultEyes,
  defaultMouth,
  defaultAccessory,
  defaultBackground
}) => {

  const handleSwapHat = (e) => setHat(e.target.value);
  const handleSwapEyes = (e) => setEyes(e.target.value);
  const handleSwapMouth = (e) => setMouth(e.target.value);
  const handleSwapAccessory = (e) => setAccessory(e.target.value);
  const handleSwapBackground = (e) => setBackground(e.target.value);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography
              fontWeight="700"
              textAlign="center"
              fontSize="24px"
              color="#003366"
              mb={1}
            >
              Swap Hats
            </Typography>
            <ToggleButtonGroup
              exclusive
              size="small"
              onChange={handleSwapHat}
              aria-label="text alignment"
            >
              <ToggleButton value={defaultHat} aria-label="left aligned">
                Default
              </ToggleButton>
              <ToggleButton value="santa_hat" aria-label="centered">
                Santa Hat
              </ToggleButton>
              <ToggleButton value="antlers" aria-label="right aligned">
                Antlers
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
          <Typography
            fontWeight="700"
            textAlign="center"
            fontSize="24px"
            color="#003366"
            mb={1}
          >
            Swap Mouths
          </Typography>
            <ToggleButtonGroup
              exclusive
              size="small"
              onChange={handleSwapMouth}
              aria-label="text alignment"
            >
              <ToggleButton value={defaultMouth} aria-label="left aligned">
                Default
              </ToggleButton>
              <ToggleButton value="santa_beard" aria-label="centered">
                Santa Beard
              </ToggleButton>
              <ToggleButton value="bell" aria-label="right aligned">
                X-mas Bell
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography
              fontWeight="700"
              textAlign="center"
              fontSize="24px"
              color="#003366"
              mb={1}
            >
              Swap Backgrounds
            </Typography>
            <ToggleButtonGroup
              exclusive
              size="small"
              onChange={handleSwapBackground}
              aria-label="text alignment"
            >
              <ToggleButton value={defaultBackground} aria-label="left aligned">
                Default
              </ToggleButton>
              <ToggleButton value="first_snow" aria-label="centered">
                First Snow
              </ToggleButton>
              <ToggleButton value="tree_lighting" aria-label="right aligned">
                X-mas Tree
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SwapForm;
