import { Grid, Typography } from "@mui/material";
import { Container, Box, Modal, ButtonGroup } from "@mui/material";
import { styled } from "@mui/system";
import { FormattedMessage } from "react-intl";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";

import ripplesTopSvg from "./assets/holiday-ripples-top.svg"; // "./assets/ripples-top.svg";
import bottomTransitionPng from "./assets/holiday-possible-traits-top.svg"; // "./assets/possible-traits-top.png";
import orcanautsLogoSvg from "./assets/orcanauts-logo.svg";
import orcaLogoSvg from "./assets/orca-logo-white.svg";
import heroLeftSvg from "./assets/holiday-hero-left.svg"; // "./assets/hero-left@3x.svg";
import heroRightSvg from "./assets/holiday-hero-right.svg"; // "./assets/hero-right@3x.svg";
import previewGif from "./assets/preview.gif";
import { StyledA } from "../styled-links";
import { MintButton } from "../mint-button";
import { CandyMachineInfo } from "../mint-button/CandyMachineInfo";
import { WalletMultiButton } from "../mint-button/WalletMultiButton";
import { useEffect, useState } from "react";
import {
  getCandyMachineState,
  CandyMachine,
} from "../mint-button/candy-machine";

import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import demoPixelnauts from '../pixel-component/pixel-demos.json';
import { PixelButton, DisabledPixelButton } from '../pixel-component/PixelButton.js';
import PixelImg from '../pixel-component/PixelImg';
import Carousel from '../pixel-component/Carousel';
import {
  getParsedNftAccountsByOwner
} from "@nfteyez/sol-rayz";
import axios from 'axios';
const UPDATE_AUTHORITY = '79SQqm8SUyLR21cXk5TEGCtkjWnN7NwBjUUY2aYUci8B';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const StyledTopDivider = styled("div")`
  height: 200px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: 125px;
  }
`;

const StyledHeroContainerBorder = styled("div")`
  position: relative;
  background: repeating-linear-gradient(
      45deg,
      white,
      white 10px,
      #ac3232 10px,
      #ac3232 20px
  );
  padding: 12px;
  border-radius: 24px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const StyledHeroContainer = styled("div")`
  background: rgba(255, 255, 255);
  padding: 24px;
  border-radius: 16px;
  min-height: 400px;
  padding-bottom: 0px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 12px;
    padding-bottom: 0px;
  }
`;

const StyledModalContainerBorder = styled("div")`
  position: absolute;
  background: repeating-linear-gradient(
      45deg,
      white,
      white 10px,
      #ac3232 10px,
      #ac3232 20px
  );
  padding: 12px;
  border-radius: 24px;

  top: 50%;
  left: 50%;
  width: 400;
  transform: translate(-50%, -50%);

  ${(props) => props.theme.breakpoints.up("sm")} {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const StyledModalContainer = styled("div")`
  background: rgba(255, 255, 255);
  padding: 24px;
  border-radius: 16px;
  padding-bottom: 0px;

  position: relative;
  top: 50%;
  width: 400;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 12px;
    padding-bottom: 0px;
  }
`;

const StyledTitleContainer = styled("div")`
  position: relative;
  left: 0;
  right: 0;
  margin-left: 32px;
  margin-right: 32px;
  background-color: #ac3232;
  border-radius: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  top: -64px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    margin-left: 8px;
    margin-right: 8px;
    .MuiTypography-root {
      font-size: 36px;
    }
  }
`;

const StyledSubtitleContainer = styled(Grid)`
  position: relative;
  top: -48px;
  align-items: center;
  justify-content: center;
`;

const StyledPreviewImage = styled("img")`
  width: 240px;
  height: 240px;
  border-radius: 24px;
`;

const CANDY_MACHINE_ID = new anchor.web3.PublicKey(
  // process.env.REACT_APP_CANDY_MACHINE_ID!
  "9aALgDk1Ryx4PQLeeaFRVHpRmR3xUoyFTvR7RVXky23S"
);

// Move this to the parent
const rpcHost = "https://orcanauts.rpcpool.com";
const connection = new anchor.web3.Connection(rpcHost);

const HeroSection = () => {
  const wallet = useAnchorWallet();
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [startDate, setStartDate] = useState<Date>();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const [orcanautPic, setOrcanautPic] = useState(previewGif);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;
      const { candyMachine, itemsAvailable, itemsRemaining, goLiveDate } =
        await getCandyMachineState(
          wallet as anchor.Wallet,
          CANDY_MACHINE_ID,
          connection
        );
      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setCandyMachine(candyMachine);
    })();
  };

  useEffect(refreshCandyMachineState, [wallet]);

  const [mints, setMints] = useState<any[]>([]);
  // if (!Array.isArray(mints) || mints.length <= 0) return null;
  const [current, setCurrent] = useState(0);

  const convertTraitName = (traitName : string) => traitName.toLowerCase()
      .replace('-',' ')
      .replace(',','')
      .replace(' ','_');

  const populateCarousel = () => {
    console.log("get orcanauts");
    setMints([]);
    if(wallet?.publicKey)
    {
      getParsedNftAccountsByOwner({publicAddress : wallet.publicKey, connection : connection}).then(allNfts =>
      {
        allNfts.map(nft => {
          if(nft.updateAuthority === UPDATE_AUTHORITY) {
            const mint : {image: string, num: number, background: string, body: string, hat: string, eyes: string, accessory: string, mouth: string } =
                { image: '', num: 0, background: '', body: '', hat: '', eyes: '', accessory: '', mouth: ''};
            axios.get(nft.data.uri).then(res => {
              res.data.attributes.map((attr :  {trait_type: string, value: string}) => {
                console.log(attr);
                if (attr.trait_type === 'bg') {
                  mint.background = convertTraitName(attr.value);
                }
                if (attr.trait_type === 'body') {
                  mint.body = convertTraitName(attr.value);
                }
                if (attr.trait_type === 'hats') {
                  mint.hat = convertTraitName(attr.value);
                }
                if (attr.trait_type === 'eyes') {
                  mint.eyes = convertTraitName(attr.value);
                }
                if (attr.trait_type === 'accessory') {
                  mint.accessory = convertTraitName(attr.value);
                }
                if (attr.trait_type === 'mouth') {
                  mint.mouth = convertTraitName(attr.value);
                }
              });
              mint.image = res.data.image;
              mint.num = res.data.edition + 1;
              setMints(oldMints => [...oldMints, mint]);
            }).catch(e => 'error getting metadata');
          }
        });
      }).catch((e) => console.log('error',e));
    }

  }

  const prevSlide = () => setCurrent(current === 0 ? mints.length - 1 : current - 1);
  const nextSlide = () => setCurrent(current === mints.length - 1 ? 0 : current + 1);

  useEffect(populateCarousel, [wallet?.publicKey]);

  return (
    <Grid container sm={12} md={9}>
      <StyledHeroContainerBorder>
        <StyledHeroContainer>
          <Grid
            alignItems="center"
            sx={{
              flexWrap: "nowrap",
              height: "100%",
              mx: "auto",
              position: "relative",
            }}
          >
            <StyledTitleContainer>
              <Typography
                variant="h2"
                color="white"
                textAlign="center"
                fontSize="40px"
              >
                <FormattedMessage defaultMessage="Generate Your Pixelnaut" />
              </Typography>
            </StyledTitleContainer>
          </Grid>
          <StyledSubtitleContainer container item xs={12}>
            <Typography
              fontWeight="700"
              textAlign="center"
              fontSize="24px"
              color="#003366"
            >
              <FormattedMessage defaultMessage="...and share the holiday joy with your friends!" />
            </Typography>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
              sx={{
                width: 240,
                height: 240,
                marginTop: "24px",
                marginBottom: "24px",
              }}
            >
            {mints.length === 0 &&
              <StyledPreviewImage src={orcanautPic} />
            }
            {mints.length > 0 &&
              <Carousel
                mints={mints}
                currentMint={current}
                setCurrentMint={setCurrent}
              />
            }
            </Grid>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              {mints.length === 0 &&
                <DisabledPixelButton
                  label={wallet?.publicKey ? "No Orcanauts Found" : "Connect to Pixelate"}
                  disabled={true}
                />
              }
              {wallet?.publicKey && mints.length > 0 &&
                <ButtonGroup size={"small"}>
                  <PixelButton label={"<"} onClick={prevSlide} />
                  <PixelButton label={"Pixelate"} onClick={handleOpenModal} />
                  <PixelButton label={">"} onClick={nextSlide} />
                </ButtonGroup>
              }
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <StyledModalContainerBorder>
                  <StyledModalContainer>
                    <PixelImg traits={mints[current]}/>
                  </StyledModalContainer>
                </StyledModalContainerBorder>
              </Modal>

            </Grid>
            <Grid item xs={10} sx={{ marginTop: "12px", marginBottom: "12px" }}>
              <CandyMachineInfo
                wallet={wallet}
                candyMachine={candyMachine}
                itemsAvailable={itemsAvailable}
                itemsRemaining={itemsRemaining}
                isSoldOut={isSoldOut}
              />
            </Grid>
          </StyledSubtitleContainer>
        </StyledHeroContainer>
      </StyledHeroContainerBorder>
    </Grid>
  );
};

// background-color: #c4feff; normal color aka not holiday - Ade
const StyledSection = styled("section")`
  background-color: #002A7A;
  background-image: url(${bottomTransitionPng}), url(${heroLeftSvg}),
    url(${heroRightSvg}), url(${ripplesTopSvg});
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: center bottom, left bottom, right bottom, center top;
  background-size: contain, auto, auto, contain;

  ${(props) => props.theme.breakpoints.down("sm")} {
    background-position: center bottom, left -100px bottom, right -75px bottom,
      center top;
    background-size: contain, 60%, 60%, contain;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    background-position: center bottom, left -100px bottom, right -75px bottom,
      center top;
  }
`;

const StyledImg = styled("img")`
  position: absolute;
  top: 120px;
  max-width: 400px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    top: 60px;
    width: 80%;
  }
`;

export const Overview = () => {
  return (
    <StyledSection>
      <Grid
        container
        display="flex"
        alignItems="end"
        justifyContent="end"
        sx={{ height: 0 }}
      >
        <WalletMultiButton sx={{ marginTop: "16px", marginRight: "16px" }} />
      </Grid>
      <StyledTopDivider />
      <Container>
        <Grid container alignItems="center" justifyContent="center">
          <StyledImg src={orcanautsLogoSvg} alt="Orcanauts logo" />
        </Grid>
        <Typography fontSize="24px" textAlign="center" style={{color: "white"}}>
          An impact-driven NFT project by
          <a href="https://orca.so" target="_blank" rel="noreferrer">
            <img
              src={orcaLogoSvg}
              alt="Orca logo"
              style={{ marginLeft: "12px", verticalAlign: "middle" }}
            />
          </a>
        </Typography>
        <br />
        <br />
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ paddingBottom: "120px" }}
        >
          <HeroSection />
        </Grid>
      </Container>
    </StyledSection>
  );
};
