import { useRef, useEffect } from "react";

import defaults from "./pixel-defaults.json";

const composeAttributes = traits => {
  let attributes = defaults;
  attributes.hat.src = `/pixel-assets/hat-${traits.hat}.png`;
  attributes.body.src = `/pixel-assets/body-${traits.body}.png`;
  attributes.eyes.src = `/pixel-assets/eyes-${traits.eyes}.png`;
  attributes.mouth.src = `/pixel-assets/mouth-${traits.mouth}.png`;
  attributes.accessory.src = `/pixel-assets/accessory-${traits.accessory}.png`;
  attributes.background.src = `/pixel-assets/background-${traits.background}.png`;
  return attributes;
};

const PixelGif = ({ traits }) => {
  const canvasRef = useRef();

  const attrs = composeAttributes(traits);

  const draw = (ctx, attrs, frameCount) => {

    const scale = ctx.canvas.width / 40;

    const hatSprite = new Image();
    const bodySprite = new Image();
    const eyesSprite = new Image();
    const mouthSprite = new Image();
    const accessorySprite = new Image();
    const backgroundSprite = new Image();

    hatSprite.src = attrs.hat.src;
    bodySprite.src = attrs.body.src;
    eyesSprite.src = attrs.eyes.src;
    mouthSprite.src = attrs.mouth.src;
    accessorySprite.src = attrs.accessory.src;
    backgroundSprite.src = attrs.background.src;


    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      backgroundSprite,
      attrs.background.width * frameCount,
      0,
      attrs.background.width,
      attrs.background.height,
      attrs.background.offsetX * scale,
      attrs.background.offsetY * scale,
      attrs.background.width * scale,
      attrs.background.height * scale
    );

    ctx.drawImage(
      bodySprite,
      attrs.body.width * frameCount,
      0,
      attrs.body.width,
      attrs.body.height,
      attrs.body.offsetX * scale,
      attrs.body.offsetY * scale,
      attrs.body.width * scale,
      attrs.body.height * scale
    );

    ctx.drawImage(
      hatSprite,
      attrs.hat.width * frameCount,
      0,
      attrs.hat.width,
      attrs.hat.height,
      attrs.hat.offsetX * scale,
      attrs.hat.offsetY * scale,
      attrs.hat.width * scale,
      attrs.hat.height * scale
    );

    ctx.drawImage(
      eyesSprite,
      attrs.eyes.width * frameCount,
      0,
      attrs.eyes.width,
      attrs.eyes.height,
      attrs.eyes.offsetX * scale,
      attrs.eyes.offsetY * scale,
      attrs.eyes.width * scale,
      attrs.eyes.height * scale
    );

    ctx.drawImage(
      mouthSprite,
      attrs.mouth.width * frameCount,
      0,
      attrs.mouth.width,
      attrs.mouth.height,
      attrs.mouth.offsetX * scale,
      attrs.mouth.offsetY * scale,
      attrs.mouth.width * scale,
      attrs.mouth.height * scale
    );

    ctx.drawImage(
      accessorySprite,
      attrs.accessory.width * frameCount,
      0,
      attrs.accessory.width,
      attrs.accessory.height,
      attrs.accessory.offsetX * scale,
      attrs.accessory.offsetY * scale,
      attrs.accessory.width * scale,
      attrs.accessory.height * scale
    );
  }

  useEffect(() => {
    console.log("play animation...");
    console.log({ attrs });
    let animationFrameCount = 0;
    let animationFrameId;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const render = () => {
      setTimeout(() => {
        animationFrameCount++;
        draw(context, attrs, animationFrameCount % 10); // keeps frameCount between 0 and 10.
        animationFrameId = window.requestAnimationFrame(render);
      }, 400); // use this to change the pace of the gif
    };
    render();

    return () => window.cancelAnimationFrame(animationFrameId);
  }, [draw])

  // <canvas> auto-resizes to fit the parent div
  return (
    <canvas ref={canvasRef} width='300' height='300'></canvas>
  );
}

export default PixelGif;
