import { css, Typography, styled, Button } from "@mui/material";

const StyledTypography = styled(Typography)`
  color: #ffffff;
  font-weight: 800;
  font-size: 28px;
`;

const baseButtonStyles = css`
  height: 74px;
  border-radius: 50px;
  padding: 20px 40px;
  text-transform: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

const StyledButton = styled(Button)`
  ${baseButtonStyles}
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(0, 0, 0, 0.25) 100%
    ),
    navy;
  background-blend-mode: overlay, normal;

  &:hover {
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(0, 0, 0, 0.25) 100%
      ),
      #5245ca;
`;

const DisabledButton = styled(Button)`
  ${baseButtonStyles}

  &.Mui-disabled {
    border: 3px solid #777777;
  }
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(12px);
  text-transform: uppercase;
  box-shadow: none;
`;

export const PixelButton = ({ label, onClick }) => {

  return (
    <StyledButton onClick={onClick}>
      <StyledTypography>{label}</StyledTypography>
    </StyledButton>
  );
};

export const DisabledPixelButton = ({ label, disabled }) => {
  return (
    <DisabledButton disabled={disabled} >
      <Typography color="#777777" fontWeight="800" fontSize="28px">
        {label}
      </Typography>
    </DisabledButton>
  );
}
