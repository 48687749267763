// Separate into a few different pieces
// - The base component that displays the actual layered image
// - A type-base that can be extended for specific components
// - A specific instantiation for this particular combination.
// Two versions of this API
// - React components for the individual parts, hard to validate
// - Configuration object

import { styled } from "@mui/system";

import { useEffect, useState } from "react";

import { Traits, TraitConfiguration } from "./orcanaut-types";

type ImgPropsOverride = Pick<React.HTMLProps<HTMLImageElement>, "width"> &
  Partial<Pick<CSSStyleDeclaration, "borderRadius" | "border">>;

const StyledImage = styled("img", {
  shouldForwardProp: (prop) => !prop.toString().startsWith("$"),
})<{ $imgProps: ImgPropsOverride }>`
  position: absolute;
  ${(props) => props.$imgProps}
`;

const LayeredImage = ({
  imgProps,
  layers,
}: {
  imgProps: ImgPropsOverride;
  layers: string[];
}) => {
  return (
    <div>
      {layers.map((layer) => (
        <StyledImage key={layer} $imgProps={imgProps} src={layer} />
      ))}
    </div>
  );
};

// We need to normalize the asset locations
// And genericize the layering approach so it can be configured instead.

interface NiftyProps {
  traitConfiguration: typeof TraitConfiguration;
  overrides: Traits;
  imgProps: ImgPropsOverride;
}

export const Nifty = (props: NiftyProps) => {
  const [imageLayers, setLayers] = useState<string[]>([]);
  useEffect(() => {
    const asyncAssets = async () => {
      const {
        traitConfiguration: {
          traits,
          invalidCombinations,
          layeringExceptions,
          alternativeVariants,
        },
      } = props;

      const config = traits.reduce((memo, trait) => {
        return {
          ...memo,
          [trait.name]: {
            // @ts-ignore Not sure how to properly type this
            variant: props.overrides[trait.name] || trait.default,
          },
        };
      }, {});

      invalidCombinations.forEach((combination) => {
        const matches = combination.every((traitVariantPair) => {
          return (
            // @ts-ignore
            config[traitVariantPair.trait].variant === traitVariantPair.variant
          );
        });
        if (matches) {
          throw new Error(
            `This is an invalid combination: ${JSON.stringify(
              combination,
              null,
              2
            )}`
          );
        }
      });

      const layerOrderingException = layeringExceptions.find(
        ({ exception }) => {
          // @ts-ignore
          return config[exception.trait].variant === exception.variant;
        }
      );

      let orderedTraits = [...traits];
      if (!!layerOrderingException) {
        // @ts-ignore
        orderedTraits = layerOrderingException.layers.map((layer) => {
          return traits.find(({ name }) => name === layer.name);
        });
      }

      const layers = orderedTraits
        .map((trait) => {
          // @ts-ignore
          const traitVariant = config[trait.name];
          if (!traitVariant || traitVariant.variant === "none") return null;

          const hasAlternativeVariant =
            // @ts-ignore
            alternativeVariants[trait.name]?.variant === traitVariant.variant;

          // @ts-ignore
          const filename = hasAlternativeVariant
            ? // @ts-ignore
              alternativeVariants[trait.name].getFilename(config)
            : `${traitVariant.variant}.png`;
          return import(`./assets/${trait.folder}/${filename}`);
        })
        .filter(Boolean);

      const assetLayers = (await Promise.all(layers)).map((mod) => mod.default);

      setLayers(assetLayers);
    };
    asyncAssets();
  }, [props]);

  return (
    imageLayers && (
      <LayeredImage
        layers={imageLayers}
        imgProps={{ width: "240px", ...props.imgProps }}
      />
    )
  );
};

export const Orcanaut = (props: Traits & Pick<NiftyProps, "imgProps">) => {
  return (
    <Nifty
      overrides={props}
      traitConfiguration={TraitConfiguration}
      imgProps={props.imgProps}
    />
  );
};

export const RareOrcanaut = (props: Pick<NiftyProps, "imgProps">) => {
  return (
    <Nifty
      overrides={{
        body: "blue",
        background: "beach",
        accessory: "mug",
        hat: "chef",
        eyes: "sleepy",
        mouth: "grin",
      }}
      traitConfiguration={TraitConfiguration}
      imgProps={props.imgProps}
    />
  );
};
