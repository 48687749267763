import { useState, useEffect } from 'react';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import './pixelnaut.css';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Carousel = ({ mints, currentMint, setCurrentMint }) => {

  return (
    <div className="container">
      <div className="carousel carousel-style item-center">
        {mints.map((mint, index) => (
          <div className={index === currentMint ? 'slide active': 'slide'} key={index}>
            {index === currentMint && <img src={mint.image} className='image' alt={`Orcanauts #${mint.num}`} />}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
