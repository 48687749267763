import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { StyledA } from "../styled-links";
import dividerRippleSvg from "./assets/divider-ripple.svg";
import scallopSvg from "./assets/scallop.svg";

const StyledSection = styled("section")({
  backgroundColor: "#53E5DF",
  color: "#004257",
});

const StyledTopDivider = styled("div")`
  height: 167px;
  background-image: url(${dividerRippleSvg});
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledAccordion = styled(Accordion)`
  background: rgba(255, 255, 255, 0.45);
  border-radius: 16px;
  margin-bottom: 20px;
  min-height: 60px;

  &:first-of-type {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  &:last-of-type {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  &:before {
    position: relative;
  }
`;

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    {...props}
    expandIcon={<img src={scallopSvg} alt="accordion icon" />}
  />
))`
  flex-direction: row-reverse;
  color: #004257;

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(180deg);
  }

  & .MuiAccordionSummary-content,
  .Mui-expanded.MuiAccordionSummary-content {
    margin-top: 12px;
    margin-left: ${(props) => props.theme.spacing(1)};
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding-left: 24px;
  padding-right: 24px;
  color: #004257;
`;

// TODO(jon): Translate this when copy is finalized
export const FAQs = () => (
  <StyledSection>
    <StyledTopDivider />
    <Container maxWidth="md" sx={{ paddingBottom: "60px" }}>
      <Typography variant="h2" textAlign="center" sx={{ marginBottom: "40px" }}>
        FAQs
      </Typography>
      <StyledAccordion>
        <StyledAccordionSummary>
          <Typography fontWeight="800" fontSize="24px">
            How can I mint an Orcanaut?
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          There are 2 mints for a total of 10,000 Orcanauts.
          <br />
          <br />
          The first mint is gated to any wallet address that have used{" "}
          <StyledA
            href="https://www.orca.so/"
            rel="noreferrer"
            target="_blank"
            $linkColor="#004257"
            $visitedColor="#004257"
          >
            orca.so
          </StyledA>{" "}
          in the past (~200,000 unique wallets). The second mint is open to
          everyone!
          <br />
          <br />
          40 Orcanauts are reserved as honoraries for partners, team members,
          and future use. Honoraries will have a separate mint address and a
          different skin to distinguish them from the primary Orcanaut
          collection.
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary>
          <Typography fontWeight="800" fontSize="24px">
            When can I mint? How much is the mint price?
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          75% of all proceeds will go to charity for financial literacy. 25%
          will go to the artist{" "}
          <StyledA
            href="https://twitter.com/corcorarium"
            rel="noreferrer"
            target="_blank"
            $linkColor="#004257"
            $visitedColor="#004257"
          >
            @corcorarium
          </StyledA>{" "}
          . Keep an eye on our{" "}
          <StyledA
            href="https://twitter.com/orca_so"
            rel="noreferrer"
            target="_blank"
            $linkColor="#004257"
            $visitedColor="#004257"
          >
            Twitter
          </StyledA>{" "}
          and{" "}
          <StyledA
            rel="noreferrer"
            target="_blank"
            href="https://discord.orca.so/"
            $linkColor="#004257"
            $visitedColor="#004257"
          >
            Discord
          </StyledA>{" "}
          for details on the charity and feature of the artist!
          <br />
          <br />
          Mint #1 (gated to addresses on the allowlist): Saturday, Nov. 27th at
          16:00 UTC for 2,000 Orcanauts. Mint price is 0.6 SOL.
          <br />
          <br />
          Mint #2 (open to everyone): Saturday, Nov. 27th at 24:00 UTC for 7,960
          Orcanauts. Mint price is 0.7 SOL.
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary>
          <Typography fontWeight="800" fontSize="24px">
            What wallets are supported?
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          We support Phantom, Sollet, Sollet Extension, Solflare, Solflare
          Extension, MathWallet, and Coin98. We recommend using a computer and
          Phantom for the best minting experience!
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary>
          <Typography fontWeight="800" fontSize="24px">
            Are there royalties?
          </Typography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          There is a 3% royalty on secondary sales that will be used for either
          Orcanaut development or donated to charity.
        </StyledAccordionDetails>
      </StyledAccordion>
    </Container>
  </StyledSection>
);
