import styled from "@mui/styled-engine";

export const StyledA = styled("a")<{
  $linkColor: string;
  $visitedColor: string;
}>`
  text-decoration: none;
  color: ${(props) => props.$linkColor};
  border-bottom: 1px solid ${(props) => props.$linkColor};

  &:visited {
    color: ${(props) => props.$visitedColor};
    border-bottom: 1px solid ${(props) => props.$visitedColor};
  }
`;
