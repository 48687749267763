import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

const rows = [
  {
    rarity: 1,
    image: "https://placekitten.com/g/140/140",
    name: "saber",
    percentage: 30,
  },
  {
    rarity: 2,
    image: "https://placekitten.com/g/140/140",
    name: "saber",
    percentage: 30,
  },
  {
    rarity: 3,
    image: "https://placekitten.com/g/140/140",
    name: "saber",
    percentage: 30,
  },
  {
    rarity: 4,
    image: "https://placekitten.com/g/140/140",
    name: "saber",
    percentage: 30,
  },
  {
    rarity: 5,
    image: "https://placekitten.com/g/140/140",
    name: "saber",
    percentage: 30,
  },
];

export const Traits = () => {
  return (
    <Container maxWidth="md">
      <section>
        <Typography variant="h2">Traits</Typography>
        <TableContainer component={Paper}>
          {/* TODO(jon): Figure out a better `aria-label` */}
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage defaultMessage="Rarity" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Picture" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage defaultMessage="Percentage" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.name + index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{"⭐".repeat(row.rarity)}</TableCell>
                  <TableCell>
                    <img src={row.image} alt={`${row.name}`} />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{`${(row.percentage / 100).toFixed(
                    2
                  )}%`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    </Container>
  );
};
