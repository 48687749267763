import { Button, ButtonProps, styled } from "@mui/material";
import { FC, MouseEventHandler, useCallback } from "react";
import { useWalletDialog } from "./useWalletDialog";

const StyledButton = styled(Button)`
  border-radius: 100px;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.6);
  border: 2px solid #4cd4ff;
  text-transform: none;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0px);
  }
`;

export const WalletDialogButton: FC<ButtonProps> = ({
  children = "Connect Wallet",
  color = "primary",
  variant = "contained",
  type = "button",
  onClick,
  ...props
}) => {
  const { setOpen } = useWalletDialog();

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (onClick) onClick(event);
      if (!event.defaultPrevented) setOpen(true);
    },
    [onClick, setOpen]
  );

  return (
    <StyledButton
      disableTouchRipple
      color={color}
      variant={variant}
      type={type}
      onClick={handleClick}
      {...props}
    >
      {children}
    </StyledButton>
  );
};
