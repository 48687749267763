import { useParams } from 'react-router-dom';
import { Grid, Typography } from "@mui/material";
import { Container, Box, Modal, ButtonGroup } from "@mui/material";
import { styled } from "@mui/system";
import { FormattedMessage } from "react-intl";
import { StyledA } from "../styled-links";

import ripplesTopSvg from "../sections/assets/holiday-ripples-top.svg"; // "./assets/ripples-top.svg";
import bottomTransitionPng from "../sections/assets/holiday-possible-traits-top.svg"; // "./assets/possible-traits-top.png";
import orcanautsLogoSvg from "../sections/assets/orcanauts-logo.svg";
import pixelnautsLogoPng from "../sections/assets/pixelnauts-logo.png";
import orcaLogoSvg from "../sections/assets/orca-logo-white.svg";
import heroLeftSvg from "../sections/assets/holiday-hero-left.svg"; // "./assets/hero-left@3x.svg";
import heroRightSvg from "../sections/assets/holiday-hero-right.svg"; // "./assets/hero-right@3x.svg";

import PixelGif from './PixelGif';

const StyledTopDivider = styled("div")`
  height: 200px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    height: 125px;
  }
`;

const StyledImg = styled("img")`
  position: absolute;
  top: 120px;
  max-width: 400px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    top: 60px;
    width: 80%;
  }
`;

const StyledHeroContainerBorder = styled("div")`
  position: relative;
  background: repeating-linear-gradient(
      45deg,
      white,
      white 10px,
      #ac3232 10px,
      #ac3232 20px
  );
  padding: 12px;
  border-radius: 24px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const StyledHeroContainer = styled("div")`
  background: rgba(255, 255, 255);
  padding: 24px;
  border-radius: 16px;
  min-height: 400px;
  padding-bottom: 0px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 12px;
    padding-bottom: 0px;
  }
`;

const StyledTitleContainer = styled("div")`
  position: relative;
  left: 0;
  right: 0;
  margin-left: 32px;
  margin-right: 32px;
  background-color: #ac3232;
  border-radius: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  top: -64px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    margin-left: 8px;
    margin-right: 8px;
    .MuiTypography-root {
      font-size: 36px;
    }
  }
`;

const StyledSubtitleContainer = styled(Grid)`
  position: relative;
  top: -48px;
  align-items: center;
  justify-content: center;
`;

const StyledSection = styled("section")`
  background-color: #002A7A;
  background-image: url(${bottomTransitionPng}), url(${heroLeftSvg}),
    url(${heroRightSvg}), url(${ripplesTopSvg});
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: center bottom, left bottom, right bottom, center top;
  background-size: contain, auto, auto, contain;

  ${(props) => props.theme.breakpoints.down("sm")} {
    background-position: center bottom, left -100px bottom, right -75px bottom,
      center top;
    background-size: contain, 60%, 60%, contain;
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    background-position: center bottom, left -100px bottom, right -75px bottom,
      center top;
  }
`;

const Greetings = () => {
  const params = useParams();

  return (
    <StyledSection>
      <StyledTopDivider />
      <Container>
        <Grid container alignItems="center" justifyContent="center">
          <StyledImg src={orcanautsLogoSvg} alt="Orcanauts logo" />
        </Grid>
        <Typography fontSize="24px" textAlign="center" style={{color: "white"}}>
          An impact-driven NFT project by
          <a href="https://orca.so" target="_blank" rel="noreferrer">
            <img
              src={orcaLogoSvg}
              alt="Orca logo"
              style={{ marginLeft: "12px", verticalAlign: "middle" }}
            />
          </a>
        </Typography>
        <br />
        <br />
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ paddingBottom: "120px" }}
        >
          <Grid container sm={12} md={9}>
            <StyledHeroContainerBorder>
              <StyledHeroContainer>
                <Grid
                  alignItems="center"
                  sx={{
                    flexWrap: "nowrap",
                    height: "100%",
                    mx: "auto",
                    position: "relative",
                  }}
                >
                  <StyledTitleContainer>
                    <Typography
                      variant="h2"
                      color="white"
                      textAlign="center"
                      fontSize="40px"
                    >
                      <FormattedMessage defaultMessage="Happy Holidays from the Orca Pod" />
                    </Typography>
                  </StyledTitleContainer>
                </Grid>
                <StyledSubtitleContainer container item xs={12}>
                  <Typography
                    fontWeight="700"
                    textAlign="center"
                    fontSize="24px"
                    color="#003366"
                  >
                    <FormattedMessage defaultMessage="...and we hope this pixelnaut makes you smile :)!" />
                  </Typography>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: 240,
                      height: 240,
                      marginTop: "24px",
                      marginBottom: "48px",
                    }}
                  >
                    <PixelGif traits={params} />
                  </Grid>
                  <Grid item xs={10} sx={{ marginTop: "24px", marginBottom: "0px" }}>
                    <Typography
                      fontSize="20px"
                      fontWeight="bold"
                      color="#003366"
                      textAlign="center"
                    >
                      Looking for your own Orcanaut? Check out {" "}
                      <StyledA
                        href="https://magiceden.io/marketplace/orcanauts"
                        $linkColor="003366"
                        $visitedColor="003366"
                      >
                        Magic Eden
                      </StyledA>
                    </Typography>
                  </Grid>
                </StyledSubtitleContainer>
              </StyledHeroContainer>
            </StyledHeroContainerBorder>
          </Grid>
        </Grid>
      </Container>
    </StyledSection>
  );
}



export default Greetings;
