import { styled } from "@mui/material";
import { Wallet } from "@solana/wallet-adapter-wallets";
import React, { DetailedHTMLProps, FC, ImgHTMLAttributes } from "react";

export interface WalletIconProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  wallet: Wallet | null;
}

const StyledImage = styled("img")`
  width: ${(props) => props.theme.spacing(3)};
  height: ${(props) => props.theme.spacing(3)};
`;

export const WalletIcon: FC<WalletIconProps> = ({ wallet, ...props }) => {
  return (
    wallet && (
      <StyledImage src={wallet.icon} alt={`${wallet.name} icon`} {...props} />
    )
  );
};
