import * as React from "react";
import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/core/ButtonUnstyled";
import { styled } from "@mui/system";
import { Typography, Button as MuiButton, Tooltip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import lockIconSvg from "../sections/assets/icon-lock@3x.svg";

const CustomButtonRoot = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "$buttonColor",
})<{ $buttonColor: string }>(
  (props) => `
  padding: 12px 32px;
  height: 66px;
  border-radius: 3.125em;
  color: #fff;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;
  text-transform: none;

  background-color: ${props.$buttonColor};

  &:hover {
    background-color: ${props.$buttonColor};
    filter: brightness(0.85);
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
    transform: translateY(-2px);
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #004386;
    transform: translateY(0);
    box-shadow: none;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
  }
`
);

type OutlinedButtonProps = {
  href?: string;
  startIcon?: JSX.Element;
};

export const OutlinedButton = styled((props: OutlinedButtonProps) => (
  <MuiButton {...props} disableTouchRipple variant="outlined" />
))`
  border: 2px solid #ffffff;
  border-radius: 100px;
  text-transform: none;
  padding: 16px 32px;

  &:hover {
    border: 2px solid #ffffff;
    background-color: #e1404f;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

export const Button = (props: any) => (
  <ButtonUnstyled {...props} component={CustomButtonRoot}>
    {props.children}
  </ButtonUnstyled>
);

const AdoptButton = () => (
  <Button
    styles={`
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), #FF4466;
    background-blend-mode: overlay, normal;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  `}
  >
    <Typography fontWeight="800" fontSize="28px">
      Adopt for 0.7 SOL
    </Typography>
  </Button>
);

const ConnectToWalletButton = () => (
  <Button
    styles={`
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), #9D3CAD;
    background-blend-mode: overlay, normal;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  `}
  >
    <Typography fontWeight="800" fontSize="28px">
      Connect Wallet
    </Typography>
  </Button>
);

const ProcessingTransactionButton = () => (
  <Button
    styles={`
    background: #1B36B6;
  `}
  >
    <Typography fontWeight="800" fontSize="28px">
      Processing...
    </Typography>
  </Button>
);

const StyledTypography = styled(Typography)`
  font-weight: 800;
  font-size: 28px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 24px;
  }
`;

export const IneligibleToMintButton = () => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <Tooltip
      arrow
      placement="top"
      title="The second mint begins on November 27th at 24:00 UTC!"
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
    >
      <span>
        <Button
          startIcon={<AccessTimeIcon />}
          $buttonColor="#777777"
          onClick={() => setShowTooltip(!showTooltip)}
        >
          <StyledTypography>Adopt for 0.7 SOL</StyledTypography>
        </Button>
      </span>
    </Tooltip>
  );
};

const SuccessfulAdoptionButton = () => (
  <Button
    styles={`
    background: #00A3A3;
  `}
  >
    <Typography fontWeight="800" fontSize="28px">
      ✓ You adopted an Orcanaut!
    </Typography>
  </Button>
);

const TransactionFailureButton = () => (
  <Button
    styles={`
    background: #323232;
  `}
  >
    <Typography fontWeight="800" fontSize="28px">
      Transaction failed
    </Typography>
  </Button>
);

export enum VARIANTS {
  ConnectToWallet,
  Adopt,
  ProcessingTransaction,
  IneligibleToMint,
  SuccessfulAdoption,
  TransactionFailure,
}

type MintButtonProps = {
  variant: VARIANTS;
};

// TODO(jon): Use XState instead
export const MintButton = (props: MintButtonProps) => {
  switch (props.variant) {
    case VARIANTS.Adopt:
      return <AdoptButton />;
    case VARIANTS.ProcessingTransaction:
      return <ProcessingTransactionButton />;
    case VARIANTS.IneligibleToMint:
      return <IneligibleToMintButton />;
    case VARIANTS.SuccessfulAdoption:
      return <SuccessfulAdoptionButton />;
    case VARIANTS.TransactionFailure:
      return <TransactionFailureButton />;
    case VARIANTS.ConnectToWallet:
    default:
      return <ConnectToWalletButton />;
  }
};

export const ViewTraitsButton = () => (
  <CustomButtonRoot $buttonColor="#172EA8">
    <Typography fontWeight="800" fontSize="28px">
      View Traits
    </Typography>
  </CustomButtonRoot>
);
