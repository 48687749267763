import { Typography } from "@mui/material";
import { CandyMachine } from "./candy-machine";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { StyledA } from "../styled-links";

type CandyMachineInfoProps = {
  wallet?: AnchorWallet;
  itemsRemaining: number;
  itemsAvailable: number;
  isSoldOut: boolean;
  candyMachine?: CandyMachine;
};

export const CandyMachineInfo = (props: CandyMachineInfoProps) => {
  const { itemsRemaining, itemsAvailable, isSoldOut, candyMachine, wallet } =
    props;

  return (
    <Typography
      fontSize="20px"
      fontWeight="bold"
      color="#003366"
      textAlign="center"
    >
      Secondary sales will be available on{" "}
      <StyledA
        href="https://magiceden.io/marketplace/orcanauts"
        $linkColor="003366"
        $visitedColor="003366"
      >
        Magic Eden
      </StyledA>
    </Typography>
  );
};
