import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// TODO(jon): Figure out how to switch the locale
const loadLocaleData = async () => {
  const messages = await import("./lang/es.json");
  return messages.default;
};

async function main() {
  const messages = await loadLocaleData();
  ReactDOM.render(
    <React.StrictMode>
      <App messages={messages} />
    </React.StrictMode>,
    document.getElementById("root")
  );
}
main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
