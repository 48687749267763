import React, { useMemo } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  getPhantomWallet,
  getSolletWallet,
  getSolletExtensionWallet,
  getSolflareWallet,
  getSolflareWebWallet,
  getMathWallet,
  getCoin98Wallet,
} from "@solana/wallet-adapter-wallets";
import {
  Overview,
  PossibleTraits,
  Roadmap,
  RevenueShare,
  FAQs,
  Team,
  Footer,
} from "./sections";
import { Traits } from "./Traits";
import Greetings from "./pixel-component/Greetings";
import { WalletDialogProvider } from "./mint-button/WalletDialogProvider";

const theme = createTheme({
  typography: {
    h2: {
      fontFamily: "Chewy",
      fontSize: 60,
    },
    fontSize: 18,
    fontFamily: "Commissioner",
  },
});

const network = WalletAdapterNetwork.Mainnet;

function App({ messages }: { messages: Record<string, string> }) {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
      getSolflareWallet(),
      getSolflareWebWallet(),
      getMathWallet(),
      getCoin98Wallet(),
    ],
    []
  );

  return (
    // TODO(jon): Figure out how to switch locales from the Orca interface
    <IntlProvider messages={messages} locale="en" defaultLocale="en">
      <ThemeProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect={true}>
            <WalletDialogProvider>
              <CssBaseline />
              <Router>
                <div className="App">
                  <Switch>
                    <Route path="/traits">
                      <Traits />
                    </Route>
                    <Route path="/share/:body/:hat/:mouth/:eyes/:accessory/:background">
                      <Greetings />
                    </Route>
                    <Route path="/">
                      <Overview />
                      <PossibleTraits />
                      <Roadmap />
                      <RevenueShare />
                      <FAQs />
                      <Team />
                      <Footer />
                    </Route>
                  </Switch>
                </div>
              </Router>
            </WalletDialogProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
