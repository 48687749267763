import { Container, Typography, Grid, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { styled } from "@mui/system";

import { Orcanaut } from "../nifty-component";
import topTransitionSvg from "./assets/ripples-orange.svg";
import backgroundTextureSvg from "./assets/sand-texture-tile.svg";
import bottomTransitionSvg from "./assets/ripples-aqua.svg";
import bubbleSideSvg from "./assets/bubble-side.svg";
import bubbleDownSvg from "./assets/bubble-down.svg";

import WhaleSvg from "./assets/whale.svg";
import KillerWhaleSvg from "./assets/killer-whale.svg";
import GuppySvg from "./assets/guppy.svg";
import StarfishSvg from "./assets/starfish.svg";
import ClownfishSvg from "./assets/clownfish.svg";
import { StyledA } from "../styled-links";

const StyledSection = styled("section")`
  background-color: #fff6e4;
  background-image: url(${topTransitionSvg}), url(${backgroundTextureSvg});
  background-repeat: no-repeat, repeat;
  background-position: top center, bottom center;
  background-size: auto, auto;
  padding-top: 192px;
`;
const StyledBottomDivider = styled("div")`
  background-image: url(${bottomTransitionSvg});
  background-repeat: no-repeat;
  background-position: center;
  height: 192px;
`;
const Backgrounds = {
  Adoption: "#FFA733",
  Community: "#F68851",
  Gallery: "#EA663E",
  Orcaverse: "#E3354C",
};

const BROWN = "#663B00";

const PhaseConnector = styled("div")<{ from: string; to: string }>`
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-left: 8px;
    top: 1px;
  }

  position: relative;
  display: block;
  top: -1px;
  margin-left: 41px;
  width: 8px;
  height: 110%;
  border: 0;
  background-image: ${(props) =>
    `linear-gradient(0deg, ${props.to} -3.05%, ${props.from} 100%)}`};
`;

const PhaseIconCircleBackground = styled("div")<{ background: string }>`
  background-color: ${(props) => props.background};
  z-index: 1;
  color: #fff;
  width: 90px;
  height: 90px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;

  ${(props) => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const SidewaysPhasePill = styled("div", {
  shouldForwardProp: (prop) => !prop.toString().startsWith("$"),
})<{
  $background: string;
  $index: number | string;
}>`
  display: none;
  ${(props) => props.theme.breakpoints.down("sm")} {
    position: absolute;
    width: 120px;
    text-align: center;
    display: block;
    background-color: ${(props) => props.$background};
    transform: rotate(-90deg) translateX(-47px) translateY(-48px);
    z-index: 1;
    border-radius: 40px;
    text-transform: uppercase;

    ${(props) =>
      props.$index === 1 &&
      "transform: rotate(-90deg) translateX(-48px) translateY(-48px);"}
  }
`;

interface RoadmapPhaseIconProps {
  className?: string;
  background: string;
  index: number | string;
}

function RoadmapPhaseIcon(props: RoadmapPhaseIconProps) {
  const { className, index, background } = props;

  return (
    <>
      <PhaseIconCircleBackground className={className} background={background}>
        <Typography
          textAlign="center"
          fontWeight="900"
          fontSize="16px"
          sx={{ textTransform: "uppercase" }}
        >
          Phase
          <br />
          <Typography
            component="span"
            fontWeight="900"
            fontSize="24px"
            lineHeight={"1"}
          >
            {index}
          </Typography>
        </Typography>
      </PhaseIconCircleBackground>
      <SidewaysPhasePill $background={background} $index={index}>
        <Typography color="#FFFFFF" fontWeight="900" fontSize="16px">
          Phase {index}
        </Typography>
      </SidewaysPhasePill>
    </>
  );
}

const StyledIconContainer = styled(Grid)`
  overflow: hidden;
  position: relative;
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

const RoadmapPhase = ({
  index,
  title,
  content,
  background,
}: {
  index: number | string;
  title: JSX.Element;
  content: JSX.Element;
  background: string;
}) => {
  let phaseConnector = null;
  if (index === 1) {
    phaseConnector = (
      <PhaseConnector from={Backgrounds.Adoption} to={Backgrounds.Community} />
    );
  } else if (index === 2) {
    phaseConnector = (
      <PhaseConnector from={Backgrounds.Community} to={Backgrounds.Gallery} />
    );
  } else if (index === 3) {
    phaseConnector = (
      <PhaseConnector from={Backgrounds.Gallery} to={Backgrounds.Orcaverse} />
    );
  }

  return (
    <Grid container>
      <StyledIconContainer item xs={1} sm={2}>
        <RoadmapPhaseIcon index={index} background={background} />
        {index < 4 ? phaseConnector : null}
      </StyledIconContainer>
      <Grid container item xs={10}>
        <Grid item xs={12} sx={{ textTransform: "uppercase" }}>
          {title}
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: "48px" }}>
          {content}
        </Grid>
      </Grid>
    </Grid>
  );
};

const PhaseTitle = ({ children }: { children: JSX.Element }) => (
  <Typography fontSize="24px" fontWeight={900} color="#663B00">
    {children}
  </Typography>
);

const SpeechBubbleDiv = styled("div")`
  background-image: url(${bubbleSideSvg});
  background-repeat: no-repeat;
  background-size: contain;
  width: 370px;
  height: 210px;
  padding: 30px;
  padding-right: 36px;

  font-family: "Chewy";
  font-size: 24px;
  color: #003366;

  ${(props) => props.theme.breakpoints.down("lg")} {
    background-image: url(${bubbleDownSvg});
    background-position: center;
    padding: 36px;
  }
`;

const StyledGrid = Grid;

const CollectibleHolders = () => (
  <StyledGrid container style={{ minHeight: "400px", marginTop: "48px" }}>
    <Grid item xs={12} lg={4}>
      <OrcanautsGrid />
    </Grid>
    <Grid
      item
      xs={12}
      lg={4}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SpeechBubbleDiv>
        Are you a Collectible HODLer?
        <Typography fontSize="16px">
          Your wallet is automatically eligible to mint one of the first 2,000
          Orcanauts on Nov 27th!
        </Typography>
      </SpeechBubbleDiv>
    </Grid>
    <Grid item xs={12} lg={4}>
      <CollectibleGrid />
    </Grid>
  </StyledGrid>
);

const StyledOrcanautContainer = styled(Grid)`
  height: 180px;
  width: 180px;
  margin-left: 24px;
`;

const StyledBox = styled(Box)`
  position: relative;

  ${(props) => props.theme.breakpoints.down("lg")} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const OrcanautsGrid = () => (
  <StyledBox>
    <Box
      display={{ xs: "flex", lg: "none" }}
      marginBottom={"24px"}
      alignItems="center"
      justifyContent="center"
      flexWrap="nowrap"
    >
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-orchid"
          body="blue"
          hat="baseball"
          eyes="sol-sunglasses"
          mouth="smirk"
          accessory="sol-beach-ball"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-honey"
          body="yellow"
          hat="none"
          eyes="anime"
          mouth="smirk"
          accessory="hedgehog"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-mellow"
          body="red"
          hat="none"
          eyes="snorkel"
          mouth="basic"
          accessory="kelp"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-coral"
          body="yellow"
          hat="headphones"
          eyes="anime"
          mouth="basic"
          accessory="phantom"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-honey"
          body="red"
          hat="flower"
          eyes="basic"
          mouth="grin"
          accessory="spaceship"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-coral"
          body="blue"
          hat="chef"
          eyes="mischievous"
          mouth="smile"
          accessory="none"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
    </Box>
    <Box
      flexWrap="nowrap"
      position="absolute"
      right={0}
      display={{ xs: "none", lg: "flex" }}
    >
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-orchid"
          body="blue"
          hat="baseball"
          eyes="sol-sunglasses"
          mouth="smirk"
          accessory="sol-beach-ball"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-honey"
          body="yellow"
          hat="none"
          eyes="anime"
          mouth="smirk"
          accessory="hedgehog"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-mellow"
          body="red"
          hat="none"
          eyes="snorkel"
          mouth="basic"
          accessory="kelp"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
    </Box>
    <Box
      flexWrap="nowrap"
      position="absolute"
      right={0}
      top="200px"
      display={{ xs: "none", lg: "flex" }}
    >
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-coral"
          body="yellow"
          hat="headphones"
          eyes="anime"
          mouth="basic"
          accessory="phantom"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-honey"
          body="red"
          hat="flower"
          eyes="basic"
          mouth="grin"
          accessory="spaceship"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
      <StyledOrcanautContainer>
        <Orcanaut
          background="basic-coral"
          body="blue"
          hat="chef"
          eyes="mischievous"
          mouth="smile"
          accessory="none"
          imgProps={{ width: "180px", borderRadius: "24px" }}
        />
      </StyledOrcanautContainer>
    </Box>
  </StyledBox>
);

const Collectible = ({
  name,
  image,
  height,
  style,
}: {
  name: string;
  image: string;
  height?: number;
  style?: object;
}) => (
  <div style={style}>
    <img height={height} src={image} alt={name} />
  </div>
);

const WhaleCollectible = (props: any) => (
  <Collectible {...props} image={WhaleSvg} name="whale collectible" />
);
const KillerWhaleCollectible = (props: any) => (
  <Collectible
    {...props}
    image={KillerWhaleSvg}
    height={180}
    name="killer whale collectible"
  />
);
const ClownfishCollectible = (props: any) => (
  <Collectible {...props} image={ClownfishSvg} name="clownfish collectible" />
);
const StarfishCollectible = (props: any) => (
  <Collectible
    {...props}
    image={StarfishSvg}
    height={64}
    name="starfish collectible"
  />
);
const GuppyCollectible = (props: any) => (
  <Collectible {...props} image={GuppySvg} name="guppy collectible" />
);

const StyledCollectibleGrid = styled("div")`
  position: relative;
  .mobile-collectible-grid {
    display: none;
  }

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 300px;
  }
`;

const CollectibleGrid = () => (
  <StyledCollectibleGrid>
    <Box display={{ xs: "none", lg: "block" }}>
      <StarfishCollectible style={{ position: "absolute", top: "68px" }} />
      <KillerWhaleCollectible
        style={{ position: "absolute", top: "120px", left: "48px" }}
      />
      <ClownfishCollectible
        style={{ position: "absolute", top: "82px", left: "188px" }}
      />
      <WhaleCollectible style={{ position: "absolute", top: "248px" }} />
      <GuppyCollectible
        style={{ position: "absolute", top: "268px", left: "200px" }}
      />
    </Box>
    <Box
      display={{ xs: "flex", lg: "none" }}
      alignItems="center"
      justifyContent="center"
    >
      <ClownfishCollectible style={{ display: "inline-block" }} />
      <WhaleCollectible
        style={{ display: "inline-block", transform: "translateY(-30px)" }}
      />
      <KillerWhaleCollectible style={{ display: "inline-block" }} />
      <GuppyCollectible style={{ display: "inline-block" }} />
      <StarfishCollectible
        style={{ display: "inline-block", transform: "translateY(-35px)" }}
      />
    </Box>
  </StyledCollectibleGrid>
);

const StyledTitleTypography = styled(Typography)`
  text-align: center;
  color: #663b00;
  margin-bottom: 12px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 3rem;
  }
`;

const StyledSubtitleTypography = styled(Typography)`
  color: ${BROWN};
  text-align: center;
  margin-bottom: 96px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: 48px;
  }
`;

export const Roadmap = () => {
  return (
    <StyledSection>
      <Container maxWidth="md">
        <StyledTitleTypography variant="h2">
          <FormattedMessage defaultMessage="Proposed Roadmap" />
        </StyledTitleTypography>
        <StyledSubtitleTypography>
          <FormattedMessage defaultMessage="The below outlines our current vision for Orcanauts, but Phase X and beyond is up to the community to make a reality!" />
        </StyledSubtitleTypography>

        <RoadmapPhase
          index={1}
          title={
            <PhaseTitle>
              <FormattedMessage defaultMessage="Adoption" />
            </PhaseTitle>
          }
          background={Backgrounds.Adoption}
          content={
            <Typography color={BROWN}>
              By adopting an Orcanaut, you’ll be one of 10,000 intrepid
              explorers at the border of NFTs and DeFi! Show your love for your
              special pet by making it your profile picture on Discord,
              Telegram, and Twitter 🐳
            </Typography>
          }
        />
        <RoadmapPhase
          index={2}
          title={
            <PhaseTitle>
              <FormattedMessage defaultMessage="The Orcanautical Community" />
            </PhaseTitle>
          }
          background={Backgrounds.Community}
          content={
            <Typography color={BROWN}>
              <FormattedMessage defaultMessage="Each one of you is unique, and we’d like to get to know you! Your Orcanaut is one of just 10,000 exclusive tickets to the Orcanautical community. Possible benefits include swag giveaways, AMAs with the Orca team, or even real-life parties!" />
            </Typography>
          }
        />
        <RoadmapPhase
          index={3}
          title={
            <PhaseTitle>
              <FormattedMessage defaultMessage="An Orcanautical Gallery" />
            </PhaseTitle>
          }
          background={Backgrounds.Gallery}
          content={
            <Typography color={BROWN}>
              <FormattedMessage defaultMessage="To help support a new generation of digital creators, our ambition is to sponsor an artist each season to create a piece of work featuring the Orcanauts. It will be designed based on input from the Orcanautical Community and could range a wide variety of mediums, from interactive digital art to auditory elements." />
            </Typography>
          }
        />
        <RoadmapPhase
          index="X"
          title={
            <PhaseTitle>
              <FormattedMessage defaultMessage="The Orcaverse" />
            </PhaseTitle>
          }
          background={Backgrounds.Orcaverse}
          content={
            <Typography color={BROWN}>
              Long-term, our dream is for Orcanauts to expand into a universe in
              their own right! This could include collaborations with other
              Solana projects, NFT art built on top of Orcanauts, or even a
              grant program for Orcanauts development. Keep an eye on our{" "}
              <StyledA
                rel="noreferrer"
                target="_blank"
                href="https://discord.orca.so/"
                $linkColor="#004257"
                $visitedColor="#004257"
              >
                Discord
              </StyledA>{" "}
              for the latest!
            </Typography>
          }
        />
      </Container>
      <CollectibleHolders />
      <StyledBottomDivider />
    </StyledSection>
  );
};
