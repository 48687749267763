import { useWallet } from "@solana/wallet-adapter-react";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Menu,
  MenuItem,
  Button,
  ButtonProps,
  styled,
  Typography,
} from "@mui/material";
import { useWalletDialog } from "./useWalletDialog";
import { WalletIcon } from "./WalletIcon";
import { WalletDialogButton } from "./WalletDialogButton";
import bubblesSvg from "./assets/bubbles.svg";

const WalletConnectButton = styled((props) => (
  <Button {...props} variant="outlined" />
))``;

const StyledConnectedWalletButton = styled(Button)`
  border-radius: 100px;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.6);
  border: 2px solid #4cd4ff;
  text-transform: none;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0px);
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    border-radius: 16px;

    background-image: url(${bubblesSvg}),
      linear-gradient(180deg, #1265e2 0%, #0e51dc 100%);
    background-position: center bottom;
    background-repeat: no-repeat;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  height: 44px;
  border-radius: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: flex-start;
  text-align: center;

  &:hover {
    background-color: #337ce6;
  }

  &:active {
    background-color: #0a46c2;
  }
`;

export const WalletMultiButton: FC<ButtonProps> = ({ children, ...props }) => {
  const { publicKey, wallet, disconnect } = useWallet();
  const { setOpen } = useWalletDialog();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey]);
  const content = useMemo(() => {
    if (children) return children;
    if (!wallet || !base58) return null;
    return base58.slice(0, 4) + ".." + base58.slice(-4);
  }, [children, wallet, base58]);

  const closeDropdown = useCallback(() => {
    return setAnchorEl(null);
  }, [setAnchorEl]);

  const openModal = useCallback(() => {
    setOpen(true);
    closeDropdown();
  }, [setOpen, closeDropdown]);

  if (!wallet)
    return (
      <WalletDialogButton {...props} variant="outlined">
        <Typography fontWeight="900">Connect Wallet</Typography>
      </WalletDialogButton>
    );
  if (!base58)
    return <WalletConnectButton {...props}>{children}</WalletConnectButton>;

  return (
    <>
      <StyledConnectedWalletButton
        variant="outlined"
        aria-expanded={open}
        style={{ pointerEvents: open ? "none" : "auto", ...props.style }}
        onClick={handleClick}
        startIcon={<WalletIcon wallet={wallet} />}
        {...props}
      >
        <Typography>{content}</Typography>
      </StyledConnectedWalletButton>
      <StyledMenu open={open} anchorEl={anchorEl} onClose={closeDropdown}>
        <StyledMenuItem onClick={openModal} role="menuitem">
          <StyledButton>
            <Typography
              fontWeight="600"
              color="#FFFFFF"
              sx={{ textTransform: "none" }}
            >
              Change Wallet
            </Typography>
          </StyledButton>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            disconnect();
            closeDropdown();
          }}
          role="menuitem"
        >
          <StyledButton>
            <Typography
              fontWeight="600"
              color="#FFFFFF"
              sx={{ textTransform: "none" }}
            >
              Disconnect Wallet
            </Typography>
          </StyledButton>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};
