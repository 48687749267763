import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Grid } from "@mui/material";
import { keyframes, styled } from "@mui/system";
import { FormattedMessage } from "react-intl";
import topTransitionSvg from "./assets/possible-traits-top.svg";
import largeBubbleSvg from "./assets/bubble-1.svg";
import { bubbleTraits } from "../nifty-component/orcanaut-types";

const StyledTopDivider = styled("div")`
  width: 100%;
  background-image: url(${topTransitionSvg});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
`;

const bounceUpAndDown = keyframes`
  0% {
    margin-top:0px;
  }
  100% {
    margin-top:50px;
  }
`;

const floatSideways = keyframes`
  0% { 
    right: -180px;
  }
  100% { 
    right: 120%;
  }
`;

const spin = keyframes`
  0% { 
    transform: rotate(-30deg);
  }
  100% { 
    transform: rotate(30deg);
  }
`;

const StyledSection = styled("section")`
  background-color: #fbaa3b;
  padding-bottom: 96px;
`;

const StyledLargeBubble = styled("div", {
  shouldForwardProp: (prop) =>
    !prop.toString().startsWith("$") && prop !== "sx",
})<{
  $size: number;
  $startingPos: string;
  $totalAnimationTime: number;
}>`
  position: absolute;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  background-image: url(${largeBubbleSvg});
  background-size: contain;
  background-repeat: no-repeat;
  animation-delay: ${(props) => props.$startingPos} !important;

  animation: ${floatSideways} ${(props) => props.$totalAnimationTime}s linear
      infinite,
    ${bounceUpAndDown} 6s ease-in-out infinite alternate,
    ${spin} 6s ease-in-out infinite alternate;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${(props) => props.$size * 0.75}px;
    height: ${(props) => props.$size * 0.75}px;
    animation: ${floatSideways}
        ${(props) => Math.floor(props.$totalAnimationTime / 2)}s linear infinite,
      ${bounceUpAndDown} 6s ease-in-out infinite alternate,
      ${spin} 6s ease-in-out infinite alternate;
  }
`;

const StyledBackgroundBubble = styled("div", {
  shouldForwardProp: (prop) =>
    !prop.toString().startsWith("$") && prop !== "sx",
})<{
  $background: string;
  $size: number;
  $startingPos: string;
  $totalAnimationTime: number;
}>`
  position: absolute;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;

  background-image: url(${(props) => props.$background});
  background-size: contain;
  background-repeat: no-repeat;
  mask-image: url(${largeBubbleSvg});
  mask-size: contain;
  mask-repeat: no-repeat;
  animation-delay: ${(props) => props.$startingPos} !important;

  box-shadow: inset 0px 0px 40px rgba(255, 255, 255, 0.5);

  animation: ${floatSideways} ${(props) => props.$totalAnimationTime}s linear
      infinite,
    ${bounceUpAndDown} 6s ease-in-out infinite alternate,
    ${spin} 6s ease-in-out infinite alternate;

  ${(props) => props.theme.breakpoints.down("sm")} {
    width: ${(props) => props.$size * 0.75}px;
    height: ${(props) => props.$size * 0.75}px;
    animation: ${floatSideways}
        ${(props) => Math.floor(props.$totalAnimationTime / 2)}s linear infinite,
      ${bounceUpAndDown} 6s ease-in-out infinite alternate,
      ${spin} 6s ease-in-out infinite alternate;
  }
`;

const StyledTraitImage = styled("img")`
  object-fit: contain;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const MIN_ANIMATION_TIME = 15;
const MAX_ANIMATION_TIME = 35;
const getRandomStartingPoint = (percentage: number) => {
  const totalAnimationTime = randomIntFromInterval(
    MIN_ANIMATION_TIME,
    MAX_ANIMATION_TIME
  );
  const size = randomIntFromInterval(100, 210);

  return {
    $size: size,
    $totalAnimationTime: totalAnimationTime,
    $startingPos: `-${Math.floor(totalAnimationTime * percentage)}s`,
  };
};

const getRandomTraitVariant = () => {
  const randomTraitIndex = randomIntFromInterval(
    0,
    Object.keys(bubbleTraits).length - 1
  );
  const randomTrait = Object.keys(bubbleTraits)[randomTraitIndex];
  // @ts-ignore
  const possibleVariants = bubbleTraits[randomTrait].filter(
    ({ name }: { name: string }) => name !== "none"
  );
  const randomVariantIndex = randomIntFromInterval(
    0,
    possibleVariants.length - 1
  );
  // @ts-ignore
  const randomVariant = possibleVariants[randomVariantIndex];

  return `${process.env.PUBLIC_URL}/traits/${randomTrait}/${randomVariant.name}.svg`;
};

const TraitsInBubbles = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const BUBBLES = [...Array.from(Array(smallScreen ? 10 : 15).keys())];

  return (
    <div style={{ minHeight: "400px", position: "relative" }}>
      {BUBBLES.map((_, index) => {
        const randomTraitVariant = getRandomTraitVariant();

        if (randomTraitVariant.includes("traits/bg/")) {
          return (
            <StyledBackgroundBubble
              key={index}
              {...getRandomStartingPoint(index / BUBBLES.length)}
              $background={randomTraitVariant}
              sx={{ top: `${randomIntFromInterval(-10, 70)}%` }}
            />
          );
        } else {
          return (
            <StyledLargeBubble
              key={index}
              {...getRandomStartingPoint(index / BUBBLES.length)}
              sx={{ top: `${randomIntFromInterval(-10, 70)}%` }}
            >
              <StyledTraitImage src={randomTraitVariant} />
            </StyledLargeBubble>
          );
        }
      })}
    </div>
  );
};

export const PossibleTraits = () => (
  <>
    <StyledTopDivider />
    <StyledSection>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sx={{ marginTop: "48px" }}>
          <Typography variant="h2" textAlign="center" color="#460000">
            <FormattedMessage defaultMessage="Possible Traits" />
          </Typography>
        </Grid>
      </Grid>
      <TraitsInBubbles />
      {/* <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ paddingTop: "48px" }}
      > */}
      {/* <Grid item>
          <Link component={RouterLink} to="/traits" underline="none">
            <ViewTraitsButton />
          </Link>
        </Grid> */}
      {/* </Grid> */}
    </StyledSection>
  </>
);
