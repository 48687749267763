import {
  Button,
  ListItem,
  ListItemProps,
  styled,
  Typography,
} from "@mui/material";
import { Wallet } from "@solana/wallet-adapter-wallets";
import React, { FC, MouseEventHandler } from "react";
import { WalletIcon } from "./WalletIcon";

interface WalletListItemProps
  extends Omit<ListItemProps, "onClick" | "button"> {
  onClick: MouseEventHandler<HTMLButtonElement>;
  wallet: Wallet;
}

const StyledWalletButton = styled(Button)`
  height: 44px;
  border-radius: 50px;
  width: 240px;
  padding: 20px 40px;
  padding-right: 0px;
  justify-content: flex-start;

  &:hover {
    background-color: #337ce6;
  }

  &:active {
    background-color: #0a46c2;
  }
`;

export const WalletListItem: FC<WalletListItemProps> = ({
  onClick,
  wallet,
  ...props
}) => {
  return (
    <ListItem {...props} sx={{ paddingTop: 0, paddingBottom: 0 }}>
      <StyledWalletButton
        onClick={onClick}
        startIcon={<WalletIcon wallet={wallet} />}
      >
        <Typography
          textAlign="left"
          color="#FFFFFF"
          sx={{ textTransform: "none" }}
        >
          {wallet.name}
        </Typography>
      </StyledWalletButton>
    </ListItem>
  );
};
